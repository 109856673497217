// 404.js
import Link from "next/link";
import { Image, Text } from "grommet";
import React from "react";

export default function FourOhFour() {
  return (
    <div className="h-100 d-flex">
      <div className="d-flex flex-column justify-content-around m-auto text-center">
        <div className="d-flex h-25 flex-column justify-content-around ">
          <Text
            size="xxlarge"
            className="HelveticaNeue-BlkCn"
            textAlign="center"
          >
            404
          </Text>
          <Text size="large" className="HelveticaNeue-BlkCn mb-4" textAlign="center">
            Page introuvable
          </Text>
        </div>

        <div style={{ textDecoration: "underline" }}>
          <Link href="/">Retour à l'accueil</Link>
        </div>
      </div>
    </div>
  );
}
